<template>
    <div class="notes_container pt-2">
        <div class="section_header mb-2">
            <h2>{{ProgressScenarioList[selectScenario].title}}</h2>
            <div class="sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                Show 6 <i  class="fas fa-angle-down"></i>
                <page-filter v-model="perPageFilter" :type="1" :is-dropdown="true" ref="notes-per-page-filter" />
            </div>
            <div class="optionDrops" @click="openFilter = !openFilter;" v-click-outside="closeOpenFilter">
                <span class="filter_btn">
                    <svg width="55" height="50" viewBox="0 0 55 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.23213 7.50897C1.1056 5.12884 0.0423259 3.93878 0.00221899 2.92738C-0.0326219 2.04877 0.344404 1.20431 1.02147 0.644465C1.80087 0 3.39547 0 6.58467 0H48.3173C51.5065 0 53.1011 0 53.8805 0.644465C54.5576 1.20431 54.9346 2.04877 54.8997 2.92738C54.8596 3.93878 53.7964 5.12884 51.6698 7.50898L35.6261 25.4659C35.2022 25.9404 34.9902 26.1776 34.8391 26.4476C34.7051 26.6871 34.6067 26.9449 34.5472 27.2128C34.48 27.5149 34.48 27.8332 34.48 28.4699V43.5254C34.48 44.076 34.48 44.3513 34.3913 44.5893C34.313 44.7997 34.1855 44.9882 34.0196 45.1392C33.8319 45.31 33.5766 45.4123 33.0661 45.6168L23.5066 49.4461C22.4732 49.86 21.9565 50.067 21.5417 49.9807C21.179 49.9052 20.8607 49.6894 20.656 49.3801C20.4219 49.0265 20.4219 48.4692 20.4219 47.3546V28.4699C20.4219 27.8332 20.4219 27.5149 20.3548 27.2128C20.2953 26.9449 20.1969 26.6871 20.0629 26.4476C19.9117 26.1776 19.6998 25.9404 19.2759 25.4659L3.23213 7.50897Z" fill="#5a5a5a" />
                    </svg>
                </span>
                <div class="dropdown_wpr progress_filter_bar" :class="openFilter ? 'active' : ''">
                    <div class="scenario_box" @click.stop="scenarioDropdown = !scenarioDropdown;" v-click-outside="closeScenarioDropdown">
                        {{ProgressScenarioList[selectScenario].title}}<i class="fas fa-angle-down"></i>
                        <div class="dropdown_wpr" :class="scenarioDropdown ? 'active' : ''">
                            <ul>
                                <li v-for="(scenario, s) in ProgressScenarioList" :key="s" @click="selectScenario = s;">{{scenario.title}}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="category_wpr">
                        <div class="category_grp active" v-for="(progress, p) of ProgressScenarioList[selectScenario].categories" :key="p">
                            <h4>{{progress.group_title}}</h4>
                            <ul>
                                <li v-for="(item, i) of progress.items" :key="i" @click.stop="item.is_selected = !item.is_selected">
                                    <label :for="`filter-${i}`" class="checkbox">
                                        <span :class="{'active' : item.is_selected}"><i class="fas fa-check"></i></span>
                                        <h5>{{ item.name }}</h5>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="report_container empty_section">
            <img src="@/assets/images/empty_habit_tracking.svg" alt="Empty">
            <h1 class="text-center">Message your coach to activate this</h1>
        </div>
        <div v-if="1 == 2">
            <ul class="note_list">
                <li>
                    <div class="notes_card">
                        <div class="card_head">
                            <ul>
                                <li>Nutrition</li>
                                <li>Fat</li>
                            </ul>
                            <div class="flag">Flag:<span class="tags high">High</span></div>
                        </div>
                        <div class="card_body">
                            <div class="field_item">
                                <h4>How many grams of fat did you have today?</h4>
                                <div class="field_area has_suffix">
                                    <input type="number" placeholder="85" value="85">
                                    <span class="suffix">gm</span>
                                </div>
                            </div>
                            <div class="notes">I messed up because my grandmother passed away and I was really sad and ate a bunch of ice cream.</div>
                        </div>
                        <div class="card_foot">
                            <div class="date">Feb 15, 2024 8:19 am (New York)</div>
                            <span class="status" v-tooltip="`Your coach has not seen this note`" position="left"><i class="fas fa-eye-slash"></i></span>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="notes_card">
                        <div class="card_head">
                            <ul>
                                <li>Nutrition</li>
                                <li>Veggies</li>
                            </ul>
                            <div class="flag">Flag:<span class="tags low">Low</span></div>
                        </div>
                        <div class="card_body">
                            <div class="field_item">
                                <h4>How many servings of vegetables did you have today?</h4>
                                <div class="field_area has_suffix">
                                    <input type="number" placeholder="2" value="2">
                                    <span class="suffix">pcs</span>
                                </div>
                            </div>
                            <div class="notes">I suck at eating broccoli. Any other options?</div>
                        </div>
                        <div class="card_foot">
                            <div class="date">Feb 14, 2024 8:19 am (New York)</div>
                            <span class="status seen" v-tooltip="`Your coach has seen this note`" position="left"><i class="fas fa-eye"></i></span>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="notes_card">
                        <div class="card_head">
                            <ul>
                                <li>Nutrition</li>
                                <li>Carbs</li>
                            </ul>
                            <div class="flag">Flag:<span class="tags range">In Range</span></div>
                        </div>
                        <div class="card_body">
                            <div class="field_item">
                                <h4>How many grams of carbs did you have today?</h4>
                                <div class="field_area has_suffix">
                                    <input type="number" placeholder="89" value="89">
                                    <span class="suffix">gm</span>
                                </div>
                            </div>
                            <div class="notes">It was really hard to stay under 100 grams today but I did it! yay me!</div>
                        </div>
                        <div class="card_foot">
                            <div class="date">Feb 13, 2024 8:19 am (New York)</div>
                            <span class="status seen" v-tooltip="`Your coach has seen this note`" position="left"><i class="fas fa-eye"></i></span>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="notes_card">
                        <div class="card_head">
                            <ul>
                                <li>Nutrition</li>
                                <li>Water</li>
                            </ul>
                            <div class="flag">Flag:<span class="tags range">In Range</span></div>
                        </div>
                        <div class="card_body">
                            <div class="field_item">
                                <h4>How much water did you drink today?</h4>
                                <div class="field_area has_suffix">
                                    <input type="number" placeholder="20" value="20">
                                    <span class="suffix">oz</span>
                                </div>
                            </div>
                            <div class="notes">It was really hard for me to drink this amount of water today, but I did it!</div>
                        </div>
                        <div class="card_foot">
                            <div class="date">Feb 12, 2024 8:19 am (New York)</div>
                            <span class="status" v-tooltip="`Your coach has not seen this note`" position="left"><i class="fas fa-eye-slash"></i></span>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="notes_card">
                        <div class="card_head">
                            <ul>
                                <li>Nutrition</li>
                                <li>Calories Consumed</li>
                            </ul>
                            <div class="flag">Flag:<span class="tags low">Low</span></div>
                        </div>
                        <div class="card_body">
                            <div class="field_item">
                                <h4>How many calories have you consumed today?</h4>
                                <div class="field_area has_suffix">
                                    <input type="number" placeholder="1200" value="1200">
                                    <span class="suffix">kcal</span>
                                </div>
                            </div>
                            <div class="notes">I didn't consume the right amount of calories today.</div>
                        </div>
                        <div class="card_foot">
                            <div class="date">Feb 11, 2024 8:19 am (New York)</div>
                            <span class="status seen" v-tooltip="`Your coach has seen this note`" position="left"><i class="fas fa-eye"></i></span>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="notes_card">
                        <div class="card_head">
                            <ul>
                                <li>Nutrition</li>
                                <li>Vitamins</li>
                            </ul>
                            <div class="flag">Flag:<span class="tags range">In Range</span></div>
                        </div>
                        <div class="card_body">
                            <div class="field_item">
                                <h4>Check the vitamins consumed today?</h4>
                                <ul class="medics">
                                    <li>
                                        <label class="checkbox">
                                            <span class="active"><i class="fas fa-check"></i></span>
                                            <h5>Vitamin A</h5>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="checkbox">
                                            <span class="active"><i class="fas fa-check"></i></span>
                                            <h5>Vitamin B1</h5>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="checkbox">
                                            <span class="active"><i class="fas fa-check"></i></span>
                                            <h5>Vitamin C</h5>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="checkbox">
                                            <span class="active"><i class="fas fa-check"></i></span>
                                            <h5>Vitamin D</h5>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="checkbox">
                                            <span class="active"><i class="fas fa-check"></i></span>
                                            <h5>Vitamin K</h5>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div class="notes">I forgot to take my Biotin today.</div>
                        </div>
                        <div class="card_foot">
                            <div class="date">Feb 10, 2024 8:19 am (New York)</div>
                            <span class="status seen" v-tooltip="`Your coach has seen this note`" position="left"><i class="fas fa-eye"></i></span>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="pagination">
                <ul>
                    <li>1</li>
                    <li class="active">2</li>
                    <li>3</li>
                    <li>4</li>
                    <li><i class="fas fa-ellipsis-h"></i></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    const PageFilter = defineAsyncComponent(() => import('@/components/PageFilter'))

    export default {
        name: 'Notes',

        data () {
            return {
                perPageFilter: 6,
                openFilter: false,
                scenarioDropdown: false,
                ProgressScenarioList: [
                    {
                        title: 'Nutrition',
                        categories:[
                            {
                                group_title: 'Nutrition',
                                items:[
                                    {
                                        name: 'Water',
                                        is_selected: 0,
                                    },
                                    {
                                        name: 'Calories Consumed',
                                        is_selected: 0,
                                    },
                                    {
                                        name: 'Fruits',
                                        is_selected: 0,
                                    },
                                    {
                                        name: 'Vegetables',
                                        is_selected: 0,
                                    },
                                    {
                                        name: 'Macros',
                                        is_selected: 0,
                                    },
                                    {
                                        name: 'Fat',
                                        is_selected: 0,
                                    },
                                    {
                                        name: 'Protein',
                                        is_selected: 0,
                                    },
                                    {
                                        name: 'Carbohydrates',
                                        is_selected: 0,
                                    },
                                    {
                                        name: 'Sugar',
                                        is_selected: 0,
                                    },
                                    {
                                        name: 'Fiber',
                                        is_selected: 0,
                                    },
                                    {
                                        name: 'Vitamins',
                                        is_selected: 0,
                                    },
                                    {
                                        name: 'Minerals',
                                        is_selected: 0,
                                    },
                                    {
                                        name: 'Supplements',
                                        is_selected: 0,
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        title: 'Fitness',
                        categories:[
                            {
                                group_title: 'Fitness',
                                items:[
                                    {
                                        name: 'Readiness',
                                        is_selected: 0,
                                    },
                                    {
                                        name: 'Step Count',
                                        is_selected: 0,
                                    },
                                    {
                                        name: 'Distance',
                                        is_selected: 0,
                                    },
                                    {
                                        name: 'Calories Burned',
                                        is_selected: 0,
                                    },
                                    {
                                        name: 'Zones',
                                        is_selected: 0,
                                    },
                                    {
                                        name: 'Resting HR',
                                        is_selected: 0,
                                    },
                                    {
                                        name: 'Blood Pressure',
                                        is_selected: 0,
                                    },
                                    {
                                        name: 'HR Variability',
                                        is_selected: 0,
                                    },
                                    {
                                        name: 'O2 Saturation',
                                        is_selected: 0,
                                    },
                                    {
                                        name: 'VO2 Max',
                                        is_selected: 0,
                                    },
                                    {
                                        name: 'Breathing Rate',
                                        is_selected: 0,
                                    },
                                    {
                                        name: 'Pulse Wave Velocity',
                                        is_selected: 0,
                                    },
                                    {
                                        name: 'Recovery',
                                        is_selected: 0,
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        title: 'Digestion',
                        categories:[
                            {
                                group_title: 'Digestion',
                                items:[
                                    {
                                        name: 'Stress',
                                        is_selected: 0,
                                    },
                                    {
                                        name: 'Mood',
                                        is_selected: 0,
                                    },
                                    {
                                        name: 'Energy',
                                        is_selected: 0,
                                    },
                                    {
                                        name: 'Libido',
                                        is_selected: 0,
                                    },
                                ]
                            },
                        ]
                    }
                ],
                selectScenario: 0,
            }
        },

        props: {
            clientPortal: Object
        },

        methods: {
            closeScenarioDropdown(){
                const vm = this;
                vm.scenarioDropdown = false;
            },
            closeOpenFilter(){
                const vm = this;
                vm.openFilter = false;
            },
            togglePerPageFilter () {
                const vm = this;

                const filter = vm.$refs['notes-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },
            closePerPageDropdown () {
                const vm = this;

                const filter = vm.$refs['notes-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },
        }
    }
</script>

<style scoped>
.section_header h2{
    margin-right: auto;
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
    color: #0E101A;
}
.section_header .sort_list{
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #5a5a5a;
    margin-right: 10px;
    position: relative;
    cursor: pointer;
}
.section_header .optionDrops {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #0E101A;
    text-align: left;
    padding: 0 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
}
.section_header .optionDrops .filter_btn{
    display: flex;
}
.section_header .optionDrops .filter_btn svg{
    width: 16px;
    height: 16px;
}
.section_header .optionDrops i {
    margin-left: 10px;
    color: #7a7a7a;
    pointer-events: none;
}
.section_header .optionDrops .dropdown_wpr {
    min-width: 160px;
    left: auto;
    right: 1px;
    overflow: hidden;
}
.section_header .optionDrops ul li {
    flex: 1 1 auto;
    border: 0;
    background: transparent;
}
.progress_filter_bar{
    width: 190px;
}
.progress_filter_bar::-webkit-scrollbar{
    display: none;
}
.progress_filter_bar.show{
    right: 0;
}
.progress_filter_bar h4{
    font-size: 15px;
    line-height: 22px;
    color: #121525;
    font-weight: 500;
    padding: 0px 20px 5px;
    display: flex;
    align-items: center;
}
.progress_filter_bar h4 .close_btn{
    font-size: 13px;
    color: #999999;
    margin-left: auto;
    cursor: pointer;
}
.progress_filter_bar .scenario_box{
    width: auto;
    border: 1px solid #e9e9e9;
    border-radius: 3px;
    position: relative;
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    color: #121525;
    padding: 8px 25px 8px 10px;
    margin: 15px;
    cursor: pointer;
}
.progress_filter_bar .scenario_box i{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #eeeeee;
    font-size: 13px;
    color: #999;
}
.progress_filter_bar .toggle_btn{
    display: flex;
    justify-content: flex-end;
    padding: 6px 20px 12px;
}
.progress_filter_bar .toggle_btn a{
    padding: 4px 10px;
    background: rgb(245, 245, 245);
    font-size: 10px;
    line-height: 12px;
    color: rgb(18, 21, 37);
    font-weight: 500;
    display: block;
    border-radius: 10px;
    cursor: pointer;
}
.progress_filter_bar .category_wpr{
    max-height: 400px;
    overflow-y: auto;
}
.progress_filter_bar .category_wpr::-webkit-scrollbar{
    width: 4px;
}
.progress_filter_bar .category_wpr::-webkit-scrollbar-thumb{
    background: #dbdbdb;
    border-radius: 2px;
}
.progress_filter_bar .category_wpr .category_grp{
    border-top: 1px solid #f5f5f5;
}
.progress_filter_bar .category_wpr .category_grp h4{
    padding: 12px 15px;
    font-size: 13px;
    line-height: 18px;
    color: #121525;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.progress_filter_bar .category_wpr .category_grp h4 i{
    margin-left: auto;
    font-size: 11px;
    color: #999999;
    transition: all 0.3s ease-in-out;
}
.progress_filter_bar .category_wpr .category_grp ul{
    display: flex;
    flex-direction: column;
    padding: 0px 15px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
}
.progress_filter_bar .category_wpr .category_grp.active ul{
    max-height: 10000px;
}
.progress_filter_bar .category_wpr ul li{
    padding: 10px 0;
    border-radius: 5px;
}
.progress_filter_bar .category_wpr ul li label.checkbox{
    justify-content: flex-start;
}
.progress_filter_bar .category_wpr ul li label.checkbox h5{
    font-size: 11px;
    line-height: 15px;
    color: #121525;
    font-weight: 500;
    padding-left: 10px;
}
.progress_filter_bar .category_wpr ul li label.checkbox span i{
    font-size: 10px;
    margin: 0;
}
.progress_filter_bar .category_wpr ul li label.checkbox span.active i{
    transform: scale(1);
    opacity: 1;
}
.note_list{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}
.note_list > li{
    flex: 0 0 33.333%;
    padding: 15px;
}
.notes_card{
    padding: 15px 20px;
    background: #fff;
    border-radius: 8px;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.notes_card .card_head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
}
.notes_card .card_head ul{
    display: flex;
    align-items: center;
}
.notes_card .card_head ul li{
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #0E101A;
    padding: 3px 10px;
    border-radius: 10px;
    background: #e9e9e9;
    margin-right: 10px;
}
.notes_card .card_head .flag{
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #0E101A;
}
.tags{
    padding: 3px 10px;
    border-radius: 11px;
    background: #e9e9e9;
    margin-left: 10px;
    color: #0E101A;
}
.tags.high{
    background: #ffefef;
    color: #fb1414;
}
.tags.low{
    background: #EAF3FF;
    color: #2f7eed;
}
.tags.range{
    background: #e8fdea;
    color: #27ab34;
}
.notes_card .card_body{
    padding: 20px 0 30px 0;
}
.notes_card .card_body .field_item{
    padding-bottom: 30px;
}
.notes_card .card_body .field_item h4{
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: #0E101A;
    min-height: 59px;
    padding-bottom: 15px;
}
.notes_card .card_body .field_area{
    display: flex;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    position: relative;
    max-width: 150px;
}
.notes_card .card_body .field_area input{
    height: 40px;
    width: 100%;
    padding: 0 5px 0 10px;
    font-size: 13px;
    color: #5a5a5a;
    background: transparent;
    pointer-events: none;
}
.notes_card .card_body .field_area .suffix{
    background: #f5f5f5;
    border-left: 1px solid #e9e9e9;
    border-radius: 0 5px 5px 0;
    height: 40px;
    min-width: 40px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 500;
}
.notes_card .card_body .medics{
    display: flex;
    flex-wrap: wrap;
}
.notes_card .card_body .medics li{
    padding: 0 7px 14px;
    pointer-events: none;
}
.notes_card .card_body .medics li label.checkbox span i {
    transform: scale(1);
    opacity: 1;
}
.notes_card .card_body .medics li label.checkbox h5{
    font-size: 13px;
    line-height: 16px;
    color: #5a5a5a;
    font-weight: 400;
    padding-left: 10px;
}
.notes_card .card_body .notes{
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: #0E101A;
    font-style: italic;
}
.notes_card .card_foot{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
}
.notes_card .card_foot .date{
    font-size: 11px;
    line-height: 16px;
    font-weight: 500;
    color: #0E101A;
}
.notes_card .card_foot .status{
    flex: 0 0 auto;
    cursor: pointer;
}
.notes_card .card_foot .status.seen{
    color: #30C13E;
}

.empty_section {
    /* border: 1px solid #D9D9D9; */
    border-radius: 10px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.empty_section img {
    max-height: 90px;
    width: auto;
    margin-bottom: 30px;
}

.empty_section h1 {
    font-size: 15px;
    line-height: 25px;
    font-weight: 400;
    color: #2c3e50;
    max-width: 580px;
    position: relative;
    background: #fff;
}

@media(max-width: 1199px){
    .note_list > li{
        flex: 0 0 50%;
    }
}
@media(max-width: 599px){
    .note_list > li{
        flex: 0 0 100%;
    }
}
</style>